import Button from '@eg/elements/Button';
import {
  CheckIllustratedIcon,
  PlusIcon,
  TrashbinIcon
} from '@eg/elements/components/Icons';
import ControlWithHint from '@eg/elements/ControlWithHint';
import DateInput from '@eg/elements/DateInput';
import { DateErrors } from '@eg/elements/utils/validation/date';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  FormikValues,
  getIn
} from 'formik';
import {
  DateObject,
  getTwoDigitNumber,
  mapIsoDateStringToDateInput,
  Person
} from 'kfo-common';
import * as React from 'react';
import { Headline } from '../components/Headline';
import InsuranceTaxHint from '../components/InsuranceTaxHint/InsuranceTaxHint';
import { getConfigVariable } from '../helpers/configurableVariables';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import {
  trackElementClicked,
  trackElementClickImmediate
} from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createBirthdatePageSchema } from '../validation/BirthdatePage';

export interface BirthdatePageData
  extends StoreStateUpdater<BirthdatePageData> {
  insuredPersons: Person[];
}

interface BirthdatePageInsuredPerson {
  personId?: string;
  birthdate?: string;
  birthdateObject?: DateObject;
}

// tslint:disable-next-line:no-any
const getBirthdateErrorMessageForIndex = (
  form: FormikProps<any>,
  name: string
): string | undefined => {
  const errorMessage = getIn(form.errors, name);
  const touched = getIn(form.touched, name);
  if (errorMessage && (touched || form.isSubmitting)) {
    return errorMessage;
  }
  return undefined;
};

const BirthdatePage = (props: PagePropsWithValues<BirthdatePageData>) => {
  const [taxHintChecked, setTaxHintChecked] = React.useState(false);
  React.useEffect(() => {
    scrollToTop();
  }, []);

  const handleInsuranceTaxHint = () => setTaxHintChecked(!taxHintChecked);

  const getZahnTarifUeberAchtzehnUrl = () => {
    return getConfigVariable('zahntarifErwachseneUrl') as string;
  };

  const mappedStoreState = {
    insuredPersons: props.storeState.insuredPersons.map(element => {
      return {
        personId: element.personId,
        birthdate: element.birthdate,
        birthdateObject: mapIsoDateStringToDateInput(element.birthdate)
      } as BirthdatePageInsuredPerson;
    })
  };

  const ICON_WIDTH = 32;

  return (
    <Formik
      initialValues={
        props.storeState.insuredPersons &&
        props.storeState.insuredPersons.length
          ? mappedStoreState
          : {
              insuredPersons: [
                {
                  personId: undefined,
                  birthdate: undefined,
                  birthdateObject: undefined
                } as BirthdatePageInsuredPerson
              ]
            }
      }
      onSubmit={(values: any) => {
        // Prevent the submitting of form in case of date error
        const dateErrorList = (values.dateError || []).filter(
          (errorValue: string) => errorValue !== undefined
        );
        if (dateErrorList.length > 0) {
          return;
        }
        const validatedValues = {
          ...props.storeState,
          ...createBirthdatePageSchema(props.valueRanges).cast(values)
        };
        props.storeState.update(validatedValues);
        props.handleAction(NavigationAction.NEXT);
      }}
      validationSchema={createBirthdatePageSchema(props.valueRanges)}
    >
      {form => {
        return (
          <Form noValidate>
            <Headline>
              Bitte geben Sie das Geburtsdatum des zu versichernden Kindes an:
            </Headline>
            <div
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <FieldArray
                  name="insuredPersons"
                  render={arrayHelpers => (
                    <>
                      {form.values.insuredPersons.map(
                        (insuredPerson: BirthdatePageInsuredPerson, index) => {
                          return (
                            <div
                              key={`birthdate-${index}`}
                              data-component-id={`insuredPerson-${index}`}
                            >
                              <Field
                                name={`insuredPersons[${index}].birthdateObject`}
                                render={({
                                  field
                                }: FieldProps<FormikValues>) => {
                                  const { setFieldValue, setFieldTouched } =
                                    form;
                                  return (
                                    <div
                                      style={{
                                        maxWidth: '400px',
                                        textAlign: 'center',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                      }}
                                    >
                                      <div
                                        className="esc_grid"
                                        style={{ margin: '1em 0' }}
                                      >
                                        <div className="esc_grid__wrapper">
                                          <div className="esc_col esc_col-8">
                                            <DateInput
                                              autoTab={true}
                                              key={`insuredPerson${index}-birthdate-field`}
                                              id={field.name}
                                              data-component-id={`insuredPerson-${index}-birthdate`}
                                              value={field.value}
                                              onBlur={() => {
                                                setFieldTouched(field.name);
                                                setFieldTouched(
                                                  `insuredPersons[${index}].birthdate`
                                                );
                                              }}
                                              onChange={(
                                                value,
                                                componentError: DateErrors
                                              ) => {
                                                if (!componentError.valid) {
                                                  setFieldValue(
                                                    `dateError[${index}]`,
                                                    componentError.badInput ||
                                                      componentError.rangeOverflow ||
                                                      componentError.rangeUnderflow ||
                                                      componentError.valueMissing
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `dateError[${index}]`,
                                                    undefined
                                                  );
                                                }
                                                const newDateObject =
                                                  value as DateObject;
                                                setFieldValue(
                                                  field.name,
                                                  value
                                                );
                                                const isoDate =
                                                  !newDateObject.year &&
                                                  !newDateObject.month &&
                                                  !newDateObject.day
                                                    ? ''
                                                    : `${
                                                        newDateObject.year
                                                      }-${getTwoDigitNumber(
                                                        newDateObject.month
                                                      )}-${getTwoDigitNumber(
                                                        newDateObject.day
                                                      )}`;
                                                setFieldValue(
                                                  `insuredPersons[${index}].birthdate`,
                                                  isoDate
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="esc_col esc_col-4">
                                            <Button
                                              variant={'text-link'}
                                              type="button"
                                              style={{ marginTop: '5px' }}
                                              data-component-id={`insuredPerson-${index}-delete-button`}
                                              disabled={
                                                form.values.insuredPersons
                                                  .length <= 1
                                              }
                                              iconLeft={TrashbinIcon}
                                              onClick={() => {
                                                trackElementClickImmediate(
                                                  TrackingElement.Button_Remove_Child
                                                );
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              löschen
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                      <ControlWithHint
                                        error={
                                          getIn(
                                            form.values,
                                            `dateError[${index}]`
                                          ) ||
                                          getBirthdateErrorMessageForIndex(
                                            form,
                                            `insuredPersons[${index}].birthdate`
                                          )
                                        }
                                      >
                                        <></>
                                      </ControlWithHint>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                      <div
                        style={{
                          maxWidth: '500px',
                          fontSize: '0.75em',
                          color: '#737373',
                          paddingTop: '1.75em',
                          paddingBottom: '6em',
                          margin: '0 auto'
                        }}
                      >
                        Eintrittsalter {props.valueRanges.ageAtEntry.min} bis{' '}
                        {props.valueRanges.ageAtEntry.max} Jahre
                      </div>
                      <InsuranceTaxHint
                        isChecked={taxHintChecked}
                        handleInsuranceTaxHint={handleInsuranceTaxHint}
                      />
                      <div>
                        <Button
                          data-component-id="add-button"
                          type="button"
                          style={{ width: '250px' }}
                          disabled={
                            form.values.insuredPersons.length >=
                            props.valueRanges.insuredPersons.max
                          }
                          variant="tertiary"
                          size="large"
                          iconLeft={PlusIcon}
                          onClick={() => {
                            trackElementClickImmediate(
                              TrackingElement.Button_Add_Child
                            );
                            arrayHelpers.push({
                              personId: undefined,
                              birthdate: undefined
                            });
                          }}
                        >
                          weiteres Kind hinzufügen
                        </Button>
                      </div>
                    </>
                  )}
                />
              </div>
              <Button
                data-component-id="next-button"
                variant="primary"
                size="large"
                style={{ margin: '2em 0 4em 0', clear: 'both', width: '250px' }}
                disabled={!taxHintChecked}
                onClick={() =>
                  trackElementClicked(TrackingElement.Button_Weiter)
                }
              >
                Weiter
              </Button>
              {!!getZahnTarifUeberAchtzehnUrl() && (
                <div style={{ marginBottom: '3em' }}>
                  <Button
                    data-component-id={'ZahnTarifUeberAchtzehn'}
                    variant="text-link"
                    onClick={() => {
                      trackElementClickImmediate(
                        TrackingElement.Button_ZahntarifUeberAchtzehn
                      );
                      window.open(getZahnTarifUeberAchtzehnUrl(), '_blank');
                    }}
                    type="button"
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Hier können Sie einen Erwachsenen absichern
                    </span>
                  </Button>
                </div>
              )}

              <div
                className="esc_grid"
                style={{ maxWidth: '500px', margin: '0 auto' }}
              >
                <div className="esc_grid__wrapper">
                  <div className="esc_col esc_col-2 esc_col-m-1">
                    <CheckIllustratedIcon
                      height={ICON_WIDTH}
                      width={ICON_WIDTH}
                      style={{ margin: 'auto' }}
                    />
                  </div>
                  <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                    Sie können bis zu 4 Kinder in einem Vertrag absichern.
                  </div>
                  <div className="esc_col esc_col-2 esc_col-m-1">
                    <CheckIllustratedIcon
                      height={ICON_WIDTH}
                      width={ICON_WIDTH}
                      style={{ margin: 'auto' }}
                    />
                  </div>
                  <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                    Der Vertrag endet mit dem 21. Lebensjahr. Anschließend kann
                    das Kind in einen anderen Zahntarif der ERGO
                    Krankenversicherung wechseln.
                  </div>
                  <div className="esc_col esc_col-2 esc_col-m-1">
                    <CheckIllustratedIcon
                      height={ICON_WIDTH}
                      width={ICON_WIDTH}
                      style={{ margin: 'auto' }}
                    />
                  </div>
                  <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                    Die Mindestlaufzeit beträgt 2 Jahre. Danach können Sie den
                    Vertrag monatlich kündigen.
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BirthdatePage;
